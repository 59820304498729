import { TEST_IDS } from '@va/constants';
import { FilterIcon } from '@va/icons';
import { useTranslate } from '@va/localization';
import { Filter, NestedFiltersPage, isNestedFiltersPage } from '@va/types/filters';
import { SearchFilterV2 } from '@va/ui/components/inputs';
import { MenuList, MenuListItemType } from '@va/ui/design-system';
import { useMemo, useState } from 'react';
import { FilterOptions, useFiltersContext } from '../filters-context';
import { FilterActionButtons } from './FilterActionButtons';
import { SubFilterTabHeader } from './SubFilterTabHeader';

export const AvailableFiltersList = ({
  availableFilters,
  onFilterClick,
  closeDropdown,
  onBackClick,
  title,
}: {
  availableFilters: FilterOptions;
  onFilterClick: (filter: Filter | NestedFiltersPage) => void;
  closeDropdown: () => void;
  onBackClick?: () => void;
  title?: string;
}) => {
  const [filterValue, setFilterValue] = useState('');

  const { shouldHighlightFilter } = useFiltersContext();

  const translate = useTranslate();

  const filteredOptions = useMemo(() => {
    if (!filterValue) return availableFilters;

    const filters: FilterOptions = [];

    const findRec = (options: FilterOptions) => {
      options.forEach((item) => {
        const isNested = isNestedFiltersPage(item);
        const labelMatches = item.label.toLowerCase().includes(filterValue.toLowerCase());

        //If filter not nested and label matches, add filter to the array;
        if (labelMatches) {
          if (
            !isNested ||
            item.filters.every((filter) => !filter.label.toLowerCase().includes(filterValue.toLowerCase()))
          ) {
            filters.push(isNested ? (item as NestedFiltersPage) : (item as Filter));
          }
        }

        //If filter is nested, add parent filter to array if searchValue doesn't match any child filter
        if (isNested) {
          findRec(item.filters);
        }
      });
    };

    findRec(availableFilters);

    return filters;
  }, [availableFilters, filterValue]);

  const menuItems = useMemo<MenuListItemType[]>(
    () =>
      filteredOptions.map((item) => {
        const isSelected = shouldHighlightFilter(item);

        return {
          id: item.id,
          'data-testid': TEST_IDS.helpers.createListItemId(item.id),
          onClick: () => {
            onFilterClick(item);
            setFilterValue('');
          },
          label: item.label,
          icon: <FilterIcon className='w-15px h-15px' color='#969696' />,
          disabled: false,
          selected: isSelected,
          logicalOperator: (item as Filter).logicalOperator,
        };
      }),
    [filteredOptions, onFilterClick, shouldHighlightFilter],
  );

  return (
    <div className='overflow-hidden gap-1.5 flex flex-col'>
      {title && onBackClick && <SubFilterTabHeader onBackClick={onBackClick} title={title} />}
      <SearchFilterV2 className='shrink-0' onChange={setFilterValue} shouldClearField={!filterValue} />
      <MenuList noItemsMessage={translate('filters.noFiltersFound')} items={menuItems} />
      <FilterActionButtons onClose={closeDropdown} />
    </div>
  );
};
