import { selectAllowUpgrade, Selectors3asFPermissions, selectStpLimit } from '@va/aaas/shared/selectors';
import { startUpgradeProcess } from '@va/dashboard/actions/app';
import { useLocalizationContext, useTranslate } from '@va/localization';
import { Button, Paragraph } from '@va/ui/design-system';
import { addNumberSeparator, is3asApp } from '@va/util/helpers';
import classNames from 'classnames';
import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { UpgradePlanTooltipComponent } from '../upgrade-plan-tooltip-component';

export function FreePlanUpgradeTooltipContent() {
  const dispatch = useDispatch();
  const translate = useTranslate();

  const onUpgrade = useCallback(() => {
    dispatch(startUpgradeProcess());
  }, [dispatch]);

  if (is3asApp()) {
    return <AAASFreePlanUpgradeTooltipComponent />;
  }

  return (
    <div className={classNames('flex flex-column gap-6px px-18px py-12px')}>
      <Paragraph className='max-w-312px'>{translate('all.freePlanTooltip.upgradePlan')}</Paragraph>
      <Button onClick={onUpgrade} text={translate('button.upgradeNow')} size='large' className='w-312px sm:w-auto' />
    </div>
  );
}

const AAASFreePlanUpgradeTooltipComponent = () => {
  const dispatch = useDispatch();
  const { translate, locale } = useLocalizationContext();
  const isUpgradeAllowed = useSelector(selectAllowUpgrade);
  const stpLimit = useSelector(selectStpLimit);
  const minStps = useSelector(Selectors3asFPermissions.limit.fullDataAccess);

  const onUpgrade = useCallback(() => {
    dispatch(startUpgradeProcess());
  }, [dispatch]);

  return (
    <UpgradePlanTooltipComponent
      title={translate('aaas.upgradeFreePlanTooltip.title')}
      text={translate('aaas.upgradeFreePlanTooltip.subtitle')}
      requiredPlanText={translate('aaas.requiredCreditsCount', { count: addNumberSeparator(minStps, locale) })}
      currentPlanText={translate('aaas.currentCreditsCount', { count: addNumberSeparator(stpLimit, locale) })}
      onUpgradeClick={isUpgradeAllowed ? onUpgrade : undefined}
      buttonClassName='bg-primary'
      buttonTextColorClassName='text-white'
      displayButtonIcon={false}
    />
  );
};
