import { AlarmingEvents } from '@va/types/events';
import { MappedEventType } from '@va/types/recordings';
import { EventType } from '@visa/rrweb';

export const scrollToEventListNode = (node: HTMLElement) => {
  setTimeout(() => {
    const parentBoundingRect = node?.parentElement?.getBoundingClientRect();
    const nodeBoundingRect = node.getBoundingClientRect();
    const scrollTopDiff = nodeBoundingRect.top - (parentBoundingRect?.top ?? 0);

    node.parentElement?.scrollBy({
      top: scrollTopDiff,
      left: 0,
      behavior: 'smooth',
    });
  }, 0);
};

export function calculateEventsCountForRecordings(
  events: MappedEventType[],
  isLoading: boolean,
  alarmingEvents: AlarmingEvents | undefined,
) {
  const regularEvents = events?.filter((event) => event.type !== EventType.Custom);
  const alarmingEventsCount = Object.values(alarmingEvents ?? {}).reduce(
    (acc: number, value) => acc + (typeof value === 'number' ? value : 0),
    0,
  );

  const eventsCount = {
    regularEvents: isLoading ? 0 : regularEvents?.length,
    alarmingEvents: isLoading ? 0 : alarmingEventsCount,
  };

  return eventsCount;
}
