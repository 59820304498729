import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';

import { startUpgradeProcess } from '@va/dashboard/actions/app';
import { getWebsiteTimezone, isWixWebsite as isWixWebsiteSelector } from '@va/dashboard/selectors/core';
import { useTranslate } from '@va/localization';
import { subscriptionStatus } from '@va/standalone/shared/constants';
import { getNoOfDaysUntil, isAccountSettingsPageSelected } from '@va/standalone/shared/helpers';
import {
  getLastCreatedSubscription,
  getLastSubscriptionExpirationDate,
  hasAppliedLifeTimeDeal as hasAppliedLifeTimeDealSelector,
  hasCanceledSubscription as hasCanceledSubscriptionSelector,
  hasFreePlan as hasFreePlanSelector,
  hasPermission,
  isSettingActiveWebsiteInProgress as isSettingActiveWebsiteInProgressSelector,
} from '@va/standalone/shared/selectors';
import { PreHeaderBadge } from '@va/ui/design-system';
import { useMemo } from 'react';
import { useLocation } from 'react-router';

export function UpgradeBadge() {
  const dispatch = useDispatch();
  const translate = useTranslate();
  const nowUnixTs = moment().unix();
  const { pathname } = useLocation();
  const timezone = useSelector(getWebsiteTimezone);
  const hasFreePlan = useSelector(hasFreePlanSelector);
  const lastSubscription = useSelector(getLastCreatedSubscription);
  const hasChangeSubscriptionPermission = useSelector(hasPermission);
  const isActiveUntil = useSelector(getLastSubscriptionExpirationDate);
  const hasAppliedLifeTimeDeal = useSelector(hasAppliedLifeTimeDealSelector);
  const subscriptionStatusValue = lastSubscription && lastSubscription.status;
  const hasCanceledSubscription = useSelector(hasCanceledSubscriptionSelector);
  const noOfActiveSubscriptionDaysLeft = getNoOfDaysUntil(isActiveUntil, timezone);
  const isSettingActiveWebsiteInProgress = useSelector(isSettingActiveWebsiteInProgressSelector);
  const canceledState =
    hasCanceledSubscription &&
    (lastSubscription.status === subscriptionStatus.ACTIVE || lastSubscription.status === subscriptionStatus.PAUSED);

  const text = useMemo(() => {
    if (!canceledState)
      return {
        message: translate('freePlan.upgradeBadgeV2.40daysLimit'),
        actionText: translate('freePlan.upgradeBadgeV2.cta'),
      };

    const messageType = noOfActiveSubscriptionDaysLeft === 0 ? 'expired' : 'counting';
    if (subscriptionStatusValue === subscriptionStatus.PAUSED) {
      return {
        message: translate('upgradeBadge.subscriptionPaused'),
        actionText: translate('button.renewNow'),
      };
    }
    return {
      message: translate(`upgradeBadge.cancelRequested.${messageType}`, {
        count: noOfActiveSubscriptionDaysLeft,
      }),
      actionText: translate('button.turnOnAutoRenewal'),
    };
  }, [canceledState, noOfActiveSubscriptionDaysLeft, subscriptionStatusValue, translate]);

  if (
    isAccountSettingsPageSelected(pathname) ||
    pathname.includes('upgrade') ||
    hasAppliedLifeTimeDeal ||
    (!hasFreePlan && (!hasCanceledSubscription || nowUnixTs > isActiveUntil)) ||
    isSettingActiveWebsiteInProgress ||
    !hasChangeSubscriptionPermission
  )
    return null;

  return (
    <PreHeaderBadge
      color={canceledState ? 'danger' : 'warning'}
      message={text.message}
      actionText={text.actionText}
      onActionClick={() => {
        dispatch(startUpgradeProcess());
      }}
    />
  );
}
